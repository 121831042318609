<nav class="navbar navbar-expand">
    <div class="container">
        <a
            class="navbar-brand col-md-2 col-sm-1 col-2"
            routerLink="/">
            <div class="nav-logo"></div>
        </a>

        <div
            loading-indicator
            class="d-flex"
            style="padding-bottom: 30px"></div>

        <div class="nav-no-collapse header-nav">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0 nav">
                @if (currentUser?.organizations?.length > 1) {
                    <kno2-org-switcher
                        [organizations]="currentUser.organizations"
                        [organizationName]="currentUser.organizationName" />
                }
                <li
                    id="notifications-shortcut"
                    *kno2ShowForRoleIn="['Administrator', 'User']"
                    data-toggle="tooltip"
                    title="Notifications">
                    <a
                        routerLink="/account/notifications"
                        class="btn">
                        <i
                            class="fa fa-bell"
                            important-alerts-badge></i>
                    </a>
                </li>

                <li
                    id="connect-menu"
                    *kno2ShowForRoleIn="['Administrator', 'User']"
                    class="dropdown"
                    data-toggle="tooltip"
                    title="Connect with Others">
                    <a
                        class="btn dropdown-toggle"
                        data-toggle="dropdown">
                        <i class="fa fa-envelope"></i>
                    </a>
                    <ul class="dropdown-menu">
                        <li
                            role="presentation"
                            class="dropdown-header">
                            Connect with Others
                        </li>
                        <li>
                            <a
                                kno2NoOpLink
                                (click)="openInvitationModal()">
                                <i class="fa fa-envelope"></i>
                                Send an Invite
                            </a>
                        </li>
                        <li>
                            <a routerLink="/invite/history">
                                <i class="fa fa-envelope"></i>
                                Previous Invites
                            </a>
                        </li>
                    </ul>
                </li>

                <li
                    id="users-shortcut"
                    *kno2ShowForRoleIn="['SysAdmin']"
                    data-toggle="tooltip"
                    title="Users">
                    <a
                        class="btn"
                        routerLink="/users">
                        <i class="fa fa-user"></i>
                    </a>
                </li>
                <li
                    id="organizations-shortcut"
                    *kno2ShowForRoleIn="['SysAdmin']"
                    data-toggle="tooltip"
                    title="Organizations">
                    <a
                        class="btn"
                        routerLink="/organizations">
                        <i class="fa fa-globe"></i>
                    </a>
                </li>

                <!-- Support Menu -->
                <li
                    id="support-menu"
                    *kno2ShowForRoleIn="['Administrator', 'User']"
                    data-toggle="tooltip"
                    title="Support Center">
                    <a
                        class="btn dropdown-toggle"
                        routerLink="/supportcenter">
                        <i class="fa fa-question-circle"></i>
                    </a>
                </li>

                <kno2-settings-menu
                    *kno2ShowForRoleIn="['Administrator']"
                    [currentUser]="currentUser" />
                <kno2-profile-menu [currentUser]="currentUser" />
            </ul>
        </div>
    </div>
</nav>
